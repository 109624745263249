<template>
  <v-container
    id="agents"
    fluid
    class="page-container">
    <v-row>
      <v-col>

        <AgentList></AgentList>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import DataComponentBase from '@/commons/DataComponentBase';
  import AgentList from '@/components/Agents/AgentList';

  export default {
    name: 'Agents',
    extends: DataComponentBase,
    components: {
      AgentList,
    },

    data () {
      return {
      }
    },

    computed: {
    },

    methods: {
    },

  };
</script>
